/* ----- Style ----- */
import '../stylesheets/tarteaucitron'

/* ----- TAC ----- */
const { tarteaucitron } = require('exports-loader?exports=tarteaucitron!tarteaucitronjs/tarteaucitron')
global.tarteaucitron = tarteaucitron
tarteaucitron.services.facebookpixel = {
    "key": "facebookpixel",
    "type": "ads",
    "name": "Facebook Pixel",
    "uri": "https://fr-fr.facebook.com/business/help/www/651294705016616",
    "needConsent": true,
    "cookies": ['datr', 'fr', 'reg_ext_ref', 'reg_fb_gate', 'reg_fb_ref', 'sb', 'wd', 'x-src'],
    "js": function () {
        "use strict";
        var n;
        if(window.fbq)return;
        n=window.fbq=function(){n.callMethod? n.callMethod.apply(n,arguments):n.queue.push(arguments)} ;
        if(!window._fbq)window._fbq=n;
        n.push=n;
        n.loaded=!0;
        n.version='2.0';
        n.queue=[];
        tarteaucitron.addScript('https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', tarteaucitron.user.facebookpixelId);
        fbq('track', 'PageView');

        if (typeof tarteaucitron.user.facebookpixelMore === 'function') {
            tarteaucitron.user.facebookpixelMore();
        }
    }
};

/* ----- Variables ----- */
const privacyPolicyUrl = '/politique-de-confidentialite'

/* ----- Functions ----- */
tarteaucitron.engage = function (id, translation_key) {
  'use strict'

  if (translation_key === null || translation_key === undefined || !tarteaucitron.lang.hasOwnProperty(translation_key)) {
    translation_key = 'fallback'
  }

  var html = '',
    r = Math.floor(Math.random() * 100000),
    engage = tarteaucitron.services[id].name + ' ' + tarteaucitron.lang[translation_key]

  if (tarteaucitron.lang['engage-' + id] !== undefined) {
    engage = tarteaucitron.lang['engage-' + id]
  }

  html += '<div class="tac_activate tac_activate_' + id + ' tac_activate--' + translation_key + '">'
  html += '   <div class="tac_float">'
  html += '      ' + engage
  html += '      <button type="button" class="tarteaucitronAllow" id="Eng' + r + 'ed' + id + '">'
  html += '          <span class="tarteaucitronCheck"></span> ' + tarteaucitron.lang.allow
  html += '       </button>'
  html += '   </div>'
  html += '</div>'

  return html
}

tarteaucitron.fixSelfXSS = function(html) { // Opsone fix for strict mode
  return html.toString().replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;').replace(/'/g, '&#039;')
}

tarteaucitron.getLanguage = function() {
  return document.documentElement.lang.substr(0, 2)
}

/* ----- Locales ----- */
switch (tarteaucitron.getLanguage()) {
  case 'fr':
    require('tarteaucitronjs/lang/tarteaucitron.fr.js')
    break
  default:
    require('tarteaucitronjs/lang/tarteaucitron.fr.js')
}

const customText = {
  "fr": {
    "alertBigPrivacy": `Notre site utilise des cookies pour améliorer votre expérience utilisateur, mais aussi garantir son bon fonctionnement et personnaliser l'affichage de nos pages ou diffuser et mesurer des publicités personnalisées. Vos préférences cookies sont modifiables à tout moment en cliquant sur « Cookies » en bas à droite de nos pages.<span class="privacy-policy-block"><br> Pour en savoir plus, consultez notre page <a href="${privacyPolicyUrl}" target="_blank">Politique de confidentialité</a>.</span>`,
    "alertSmall": "Cookies",
    "middleBarHead": "Gérer mes cookies",
    "disclaimer": `En autorisant ces services tiers, vous acceptez le dépôt et la lecture de cookies et l'utilisation de technologies de suivi nécessaires à leur bon fonctionnement.<br><br> Marketing et Reciblage : Ces cookies sont généralement définis par nos partenaires marketing et publicitaires. Ils peuvent être utilisés par eux pour créer un profil de vos intérêts et vous montrer plus tard des publicités pertinentes et personnalisées. Si vous n’autorisez pas ces cookies, vous ne verrez pas de publicités ciblées selon vos centres d’intérêt.<br> Retrouvez ici les informations de notre partenaire Google : <a href="https://policies.google.com/technologies/partner-sites" target="_blank">https://policies.google.com/technologies/partner-sites</a>.<br><br> Vos préférences sont modifiables à tout moment en cliquant sur <span class="is-nowrap">« Cookies »</span> en bas à droite de nos pages.<br> Pour en savoir plus, consultez notre <a href="${privacyPolicyUrl}" target="_blank">Politique de confidentialité</a>.`,
    "fallback_required": "est désactivé.<br /> L'autorisation de ce service est obligatoire pour utiliser ce formulaire.",
  },
}

let tarteaucitronCustomText = tarteaucitron.getLanguage() in customText ? customText[tarteaucitron.getLanguage()] : ''
tarteaucitron.lang = { ...tarteaucitron.lang, ...tarteaucitronCustomText }

/* ----- Services ----- */
require('tarteaucitronjs/tarteaucitron.services.js')

tarteaucitron.services.mtcaptcha = {
  "key": "mtcaptcha",
  "type": "api",
  "name": "MTCaptcha",
  "uri": "https://www.mtcaptcha.com",
  "readmoreLink": "https://www.mtcaptcha.com/faq-cookie-declaration",
  "needConsent": true,
  "cookies": ['mtv1Pulse', 'mtv1ConfSum', 'mtv1Pong', 'mtv1*'],
  "js": function () {
    "use strict";
    if (document.querySelector('.mtcaptcha')) {
      (function(){var mt_service = document.createElement('script');mt_service.async = true;mt_service.src = 'https://service.mtcaptcha.com/mtcv1/client/mtcaptcha.min.js';(document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(mt_service);
      var mt_service2 = document.createElement('script');mt_service2.async = true;mt_service2.src = 'https://service2.mtcaptcha.com/mtcv1/client/mtcaptcha2.min.js';(document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(mt_service2);}) ();
    }
  },
  "fallback": function () {
    "use strict";
    var id = 'mtcaptcha';
    tarteaucitron.fallback(['mtcaptcha'], tarteaucitron.engage(id, 'fallback_required'));
  }
}

tarteaucitron.services.tripadvisorwidget = {
  "key": "tripadvisorwidget",
  "type": "comment",
  "name": "Tripadvisor",
  "needConsent": true,
  "cookies": [],
  "uri": "https://www.tripadvisor.fr",
  "readmoreLink": "https://www.tripadvisor.fr/Attraction_Review-g6865085-d3319464-Reviews-Aventure_Floreval-Bruyeres_le_Chatel_Essonne_Ile_de_France.html", // If you want to change readmore link
  "js": function () {
    "use strict";
    if (document.querySelector('.tripadvisorwidget')) {
      var script = document.createElement('script');
      script.async = true;
      script.src = 'https://www.jscache.com/wejs?wtype=selfserveprop&uniq=593&locationId=3319464&lang=fr&rating=true&nreviews=4&writereviewlink=true&popIdx=false&iswide=true&border=false&display_version=2';
      script.setAttribute('data-loadtrk', '');

      script.onreadystatechange = script.onload = function () {
        this.loadtrk = true;
        waitForTripAdvisor();
      };

      document.querySelector('.tripadvisorwidget').appendChild(script);
    }

    function waitForTripAdvisor() {
      if (typeof window.taValidate !== 'undefined') {
        window.taValidate();
      } else {
        setTimeout(waitForTripAdvisor, 100);
      }
    }
  },
  "fallback": function () {
    "use strict";
    var id = 'tripadvisorwidget';
    tarteaucitron.fallback(['tripadvisorwidget'], tarteaucitron.engage(id, 'fallback'));
  }
};

/* ----- Initialization ----- */
tarteaucitron.init({
  privacyUrl: privacyPolicyUrl, /* Privacy policy url */
  hashtag: '#tarteaucitron', /* Open the panel with this hashtag */
  cookieName: 'tarteaucitron', /* Cookie name */
  orientation: 'middle', /* Banner position (top - bottom - middle - popup) */
  groupServices: false, /* Group services by category */
  showAlertSmall: true, /* Show the small banner on bottom right */
  cookieslist: false, /* Show the cookie list */
  closePopup: false, /* Show a close X on the banner */
  showIcon: false, /* Show cookie icon to manage cookies */
  //iconSrc: '', /* Optionnal: URL or base64 encoded image */
  iconPosition: 'BottomRight', /* BottomRight, BottomLeft, TopRight and TopLeft */
  adblocker: false, /* Show a Warning if an adblocker is detected */
  denyAllCta: true, /* Show the deny all button */
  acceptAllCta: true, /* Show the accept all button when highPrivacy on */
  highPrivacy: true, /* HIGHLY RECOMMANDED Disable auto consent */
  handleBrowserDNTRequest: false, /* If Do Not Track == 1, disallow all */
  removeCredit: true, /* Remove credit link */
  moreInfoLink: true, /* Show more info link */
  useExternalCss: true, /* If false, the tarteaucitron.css file will be loaded */
  useExternalJs: true, /* If false, the tarteaucitron.js file will be loaded */
  //cookieDomain: '.my-multisite-domaine.fr', /* Shared cookie for multisite */
  readmoreLink: '', /* Change the default readmore link */
  mandatory: true, /* Show a message about mandatory cookies */
})

document.addEventListener('turbolinks:render', () => {
  tarteaucitron.added = {}
  tarteaucitron.initEvents.loadEvent(false)
  tarteaucitron.job.forEach((e) => { tarteaucitron.job.push(e) })
})

/* ----- Add services to TAC ----- */
tarteaucitron.user.hubspotId = '143766552';
tarteaucitron.user.facebookpixelId = '1029839398681299';

tarteaucitron.job = []
tarteaucitron.job.push('mtcaptcha')
tarteaucitron.job.push('maps_noapi')
tarteaucitron.job.push('tripadvisorwidget')
tarteaucitron.job.push('facebookpixel')


tarteaucitron.job.push('hubspot')


